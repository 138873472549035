import { wfGet, wfPut, wfDelete, wfPost } from 'actions';
import { camelToSnakeCase } from '../../helpers';

export const getNetworkMerchant = id => async dispatch => {
  try {
    const networkMerchant = await dispatch(wfGet(`/api/network-merchant/${id}`));
    return networkMerchant;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworkMerchantHistory = id => async dispatch => {
  try {
    const history = await dispatch(wfGet(`/api/network-merchant/${id}/history`));
    return history;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworkMerchantCommissionRateHistory = id => async dispatch => {
  try {
    const history = await dispatch(wfGet(`/api/network-merchant/${id}/history/commission-rate`));
    return history;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworkMerchantCountryHistory = id => async dispatch => {
  try {
    const history = await dispatch(wfGet(`/api/network-merchant/${id}/history/country`));
    return history;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworkMerchantCommissionHistory = id => async dispatch => {
  try {
    const commissionHistory = await dispatch(wfGet(`/api/network-merchant/${id}/commission-history`));
    return commissionHistory;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCoupons = (id, filters) => async dispatch => {
  let uri = `/api/network-merchant/${id}/coupon`;

  const searchParams = new URLSearchParams();

  for (let f in filters) {
    if (filters[f] !== undefined) {
      searchParams.set(camelToSnakeCase(f), filters[f]);
    }
  }

  if (searchParams.toString().length !== 0) {
    uri += `/?${searchParams.toString()}`;
  }

  try {
    const coupons = await dispatch(wfGet(uri));
    return coupons;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createCoupon = coupon => async dispatch => {
  try {
    await dispatch(wfPost(`/api/network-merchant-coupon/`, coupon));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCoupon = coupon => async dispatch => {
  try {
    await dispatch(wfPut(`/api/network-merchant-coupon/${coupon.ID}`, coupon));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworkMerchantCommissions = (id, filters) => async dispatch => {
  let uri = `/api/network-merchant/${id}/commission`;

  const searchParams = new URLSearchParams();

  for (let f in filters) {
    if (filters[f] !== undefined) {
      searchParams.set(camelToSnakeCase(f), filters[f]);
    }
  }

  if (searchParams.toString().length !== 0) {
    uri += `/?${searchParams.toString()}`;
  }

  try {
    const commissions = await dispatch(wfGet(uri));
    return commissions;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateNetworkMerchantCommission = commission => async dispatch => {
  try {
    const userEmail = localStorage.getItem('userEmail');
    const body = {
      ...commission,
      ModifiedAuthor: userEmail ? userEmail : 'merchant admin nm commission update',
    };
    await dispatch(wfPut(`/api/network-merchant-commission/${commission.ID}`, body));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworkMerchantKeywords = id => async dispatch => {
  try {
    const keywords = await dispatch(wfGet(`/api/network-merchant/${id}/keyword`));
    return keywords;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateNetworkMerchant = updatedNetworkMerchant => async dispatch => {
  try {
    await dispatch(wfPut(`/api/network-merchant/${updatedNetworkMerchant.ID}`, updatedNetworkMerchant));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const disableNetworkMerchantKeyword =
  (id, { KeywordName }) =>
  async dispatch => {
    const body = {
      KeywordName,
      Disabled: true,
    };
    try {
      await dispatch(wfPut(`/api/network-merchant/${id}/keyword`, body));
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteNetworkMerchantKeyword = id => async dispatch => {
  try {
    await dispatch(wfDelete(`/api/network-merchant/${id}/keyword`));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const upsertNetworkMerchantKeyword = (id, keyword) => async dispatch => {
  try {
    await dispatch(wfPut(`/api/network-merchant/${id}/keyword`, keyword));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworkMerchantAudit = (id, kind) => async dispatch => {
  try {
    const audit = await dispatch(wfGet(`/api/network-merchant/${id}/audit/${kind}`));
    return audit;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createNetworkMerchantAudit = (id, audit) => async dispatch => {
  try {
    await dispatch(wfPost(`/api/network-merchant/${id}/audit`, audit));
  } catch (error) {
    return Promise.reject(error);
  }
};
