import React, { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'helpers/getErrorMessage';
import { filter, find, uniqBy, uniq } from 'lodash';
import { Button, Card, Dropdown, Loader } from 'semantic-ui-react';

export type AgingReport = {
  reportType: string;
  name: string;
  month: string; // Format: "YYYY-MM"
  id: number;
  fileName: string;
};

function someUndefined(...args: unknown[]): boolean {
  return args.some(arg => arg === undefined);
}

// Format month for display (YYYY-MM to Month YYYY)
const formatMonth = (monthStr: string) => {
  if (monthStr === 'All time') {
    return monthStr;
  }

  const [year, month] = monthStr.split('-');
  return new Date(`${year}-${month}-01`).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    timeZone: 'GMT',
  });
};

type Props = {
  listAgingReports: () => Promise<AgingReport[]>;
  getAgingReport: (fileName: string) => Promise<string>;
};

export default function AgingReportExplorer({ listAgingReports, getAgingReport }: Props) {
  const [reports, setReports] = useState<AgingReport[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const [selectedReportType, setSelectedReportType] = useState<AgingReport['reportType']>();
  const [selectedName, setSelectedName] = useState<AgingReport['name']>();
  const [selectedMonth, setSelectedMonth] = useState<AgingReport['month']>();

  useEffect(() => {
    async function getReports() {
      try {
        setIsFetching(true);
        const res = await listAgingReports();
        setReports(res);
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
      setIsFetching(false);
    }

    getReports();
  }, []);

  // Get unique report types
  const reportTypes = useMemo(() => {
    return uniq(reports.map(r => r.reportType));
  }, [reports]);

  // Get filtered items based on selected report type
  const nameOptions = useMemo(() => {
    if (!selectedReportType) {
      return [];
    }

    return uniqBy(filter(reports, { reportType: selectedReportType }), x => x.name).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }, [selectedReportType]);

  // Get filtered months based on selected report type and id
  const months = useMemo(() => {
    if (someUndefined(selectedReportType, selectedName)) {
      return [];
    }

    return filter(reports, { reportType: selectedReportType, name: selectedName })
      .map(r => r.month)
      .sort((a, b) => {
        if (a === 'All time') return -1;
        return b.localeCompare(a);
      }); // sort reverse chronologically
  }, [selectedReportType, selectedName]);

  // Reset dependent fields when a selection changes
  const handleReportTypeChange = (reportType: string) => {
    setSelectedReportType(reportType);
    setSelectedName(undefined);
    setSelectedMonth(undefined);
  };

  const handleNameChange = (name: string) => {
    setSelectedName(name);
    setSelectedMonth(undefined);
  };

  // Find the report based on the user's selections
  const selectedReport = useMemo(() => {
    if (someUndefined(selectedReportType, selectedName, selectedMonth)) {
      return undefined;
    }

    return find(reports, { reportType: selectedReportType, name: selectedName, month: selectedMonth });
  }, [selectedReportType, selectedName, selectedMonth]);

  const downloadReport = async (selectedReport: AgingReport) => {
    const { reportType, name, month } = selectedReport;

    try {
      setIsFetching(true);
      const res = await getAgingReport(selectedReport.fileName);
      const csvContent = new Blob([res], { type: 'text/csv' });
      const fileName = `${reportType}-${name}-${month}.csv`;

      const link = document.createElement('a');
      link.setAttribute('href', window.URL.createObjectURL(csvContent));
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
    setIsFetching(false);
  };

  return (
    <Card fluid className="!max-w-lg">
      <Card.Content
        header="Aging Report Explorer"
        className="flex flex-row items-center justify-between space-y-0 pb-2 mb-8"
      />
      <Card.Content className="space-y-4 font-montserrat">
        <Loader active={isFetching} />
        <div className="space-y-2">
          <div>
            <label htmlFor="report-type" className="text-sm font-semibold">
              Report Type
            </label>
          </div>
          <Dropdown
            search
            selection
            fluid
            placeholder="Select report type"
            options={reportTypes.map(rt => ({ value: rt, text: rt }))}
            value={selectedReportType}
            onChange={(e, data) => handleReportTypeChange(data.value as string)}
            disabled={isFetching}
          />
        </div>

        <div className="space-y-2">
          <div>
            <label htmlFor="name" className="text-sm font-semibold">
              Name
            </label>
          </div>
          <Dropdown
            fluid
            search
            selection
            placeholder="Select name"
            options={nameOptions.map(r => ({ value: r.name, text: `(${r.id}) - ${r.name}` }))}
            value={selectedName?.toString()}
            onChange={(e, data) => handleNameChange(data.value as string)}
            disabled={!selectedReportType || isFetching}
          />
        </div>

        <div className="space-y-2">
          <div>
            <label htmlFor="month" className="text-sm font-semibold">
              Month
            </label>
          </div>
          <Dropdown
            fluid
            search
            selection
            placeholder="Select month"
            options={months.map(m => ({ value: m, text: formatMonth(m) }))}
            value={selectedMonth}
            onChange={(e, data) => setSelectedMonth(data.value as string)}
            disabled={selectedName === undefined || isFetching}
          />
        </div>

        {selectedReport && (
          <Button
            disabled={isFetching}
            className="w-full mt-6"
            color="black"
            onClick={() => downloadReport(selectedReport)}
          >
            Download CSV
          </Button>
        )}
      </Card.Content>
    </Card>
  );
}
