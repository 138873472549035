import React, { useEffect, useState } from 'react';
import { Segment, Icon, Modal } from 'semantic-ui-react';
import CommissionHistoryLog from './CommissionHistoryLog';
import CommissionsTable from './CommissionsTable';
import NetworkMerchantAudit from 'components/NetworkMerchantAudit/NetworkMerchantAudit';

import './Commission.css';

const AUDIT_KIND = 'NETWORK_MERCHANT_COMMISSIONS';

const CommissionsModal = ({
  commissions,
  handleUpdateCommission,
  commissionHistory,
  commissionHistoryIsLoading,
  networkMerchant,
  auditIsLoading,
  getNetworkMerchantAudit,
  createNetworkMerchantAudit,
}) => {
  const [networkMerchantAudit, setNetworkMerchantAudit] = useState(null);

  const fetchNetworkMerchantAudit = async () => {
    try {
      const networkMerchantAudit = await getNetworkMerchantAudit(AUDIT_KIND);

      if (networkMerchantAudit?.auditKind === AUDIT_KIND) {
        setNetworkMerchantAudit(networkMerchantAudit);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNetworkMerchantAudit();
  }, []);

  return (
    <Segment.Group className="commissions">
      <Modal
        className="centered"
        closeIcon={true}
        size="large"
        trigger={
          <Segment padded className="header">
            <h3 style={{ textAlign: 'center' }}>
              <Icon name="dollar" />
              Commissions ({commissions.length})
            </h3>
          </Segment>
        }
      >
        <Modal.Header>
          <div className="flex items-center">
            <div className="mr-3">
              <Icon name="dollar" />
              Commissions (Active)
            </div>
            <NetworkMerchantAudit
              networkMerchantAudit={networkMerchantAudit}
              isLoading={auditIsLoading}
              createNetworkMerchantAudit={() => {
                createNetworkMerchantAudit(AUDIT_KIND);
                fetchNetworkMerchantAudit();
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <CommissionsTable commissions={commissions} handleUpdateCommission={handleUpdateCommission} />
          <CommissionHistoryLog
            commissionHistory={commissionHistory}
            commissions={commissions}
            isLoading={commissionHistoryIsLoading}
            nm={networkMerchant}
          />
        </Modal.Content>
      </Modal>
    </Segment.Group>
  );
};

export default CommissionsModal;
