import { connect } from 'react-redux';
import { getAgingReport, listAgingReports } from 'actions/agingReport/agingReport';
import AgingReportExplorer from './AgingReportExplorer';

const mapActionsToProps = {
  getAgingReport,
  listAgingReports,
};

export default connect(null, mapActionsToProps)(AgingReportExplorer);
