import React from 'react';
import { Button, Label, Loader, Popup } from 'semantic-ui-react';
import { getTimeDifference } from 'helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { checkIsNetworkMerchantAuditor } from 'helpers/auth0';
import { NetworkMerchantAudit as NetworkMerchantAuditType } from './types';

interface NetworkMerchantAuditProps {
  networkMerchantAudit: NetworkMerchantAuditType | null;
  isLoading: boolean;
  createNetworkMerchantAudit: () => void;
}

const NetworkMerchantAudit = ({
  networkMerchantAudit,
  isLoading,
  createNetworkMerchantAudit,
}: NetworkMerchantAuditProps) => {
  const { user } = useAuth0();
  const isNetworkMerchantAuditor = checkIsNetworkMerchantAuditor(user);

  return (
    <div className="flex items-center mt-1">
      {networkMerchantAudit ? (
        <Popup
          className="!p-2 !font-montserrat !text-xs !whitespace-nowrap"
          content={`Audited by ${networkMerchantAudit.auditedBy} on ${networkMerchantAudit.auditDate?.split('T')[0]}`}
          trigger={
            <Label className="!font-montserrat !text-xs !px-2 !py-1 !bg-success-green !bg-opacity-30 !border-success-green !border-[1px] !text-success-green !rounded-full">
              {`Audited ${getTimeDifference(networkMerchantAudit.auditDate)}`}
              {isNetworkMerchantAuditor && (
                <Button
                  className="!ml-1 !text-xs !px-2 !py-1 !bg-success-green !bg-opacity-30 !rounded-full !text-success-green !font-bold hover:!text-white hover:!bg-opacity-50"
                  onClick={createNetworkMerchantAudit}
                >
                  +
                </Button>
              )}
            </Label>
          }
        />
      ) : (
        isNetworkMerchantAuditor && (
          <Button
            className="network-merchant-audit min-w-[120px] !font-montserrat !text-xs !px-2 !py-1 !bg-button-light-grey hover:!bg-[#e0e0e0] !bg-opacity-30 !border !border-black !text-black !rounded-full"
            onClick={createNetworkMerchantAudit}
          >
            {isLoading ? <Loader active size="mini" inline="centered" /> : '+ Create a new audit'}
          </Button>
        )
      )}
    </div>
  );
};

export default NetworkMerchantAudit;
