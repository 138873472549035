import { wfCSVGet, wfGet } from 'actions';

export const listAgingReports = () => async dispatch => {
  try {
    const agingReport = await dispatch(wfGet(`/api/aging-report`));
    return agingReport;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAgingReport = fileName => async dispatch => {
  try {
    const agingReport = await dispatch(wfCSVGet(`/api/aging-report/download?fileName=${encodeURIComponent(fileName)}`));
    return agingReport.body;
  } catch (error) {
    return Promise.reject(error);
  }
};
