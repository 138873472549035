import React, { useState, useEffect } from 'react';
import { Table, TableBody, Dropdown, Checkbox } from 'semantic-ui-react';
import { Network } from 'pages/CommissionRateQueue/CommissionRateQueue';
import CommissionRateQueueTableHeader from './CommissionRateQueueTableHeader';
import CommissionRateQueueTableRow from './CommissionRateQueueTableRow';
import { CommissionRateQueueItem, CommissionRateQueueStatus } from './types';

interface CommissionRateQueueTableProps {
  data: CommissionRateQueueItem[];
  networks: Network[];
  onApprove: (item: CommissionRateQueueItem) => void;
  onRevise: (item: CommissionRateQueueItem) => void;
}

const statusOptions = [
  { key: 'PENDING', text: 'Pending', value: 'PENDING' },
  { key: 'APPROVED', text: 'Approved', value: 'APPROVED' },
  { key: 'REVISED', text: 'Revised', value: 'REVISED' },
];

const CommissionRateQueueTable: React.FC<CommissionRateQueueTableProps> = ({ data, networks, onApprove, onRevise }) => {
  const [activeStatusFilter, setActiveStatusFilter] = useState<CommissionRateQueueStatus>('PENDING');
  const [showNeedsReviewAtTop, setShowNeedsReviewAtTop] = useState(false);
  const [filteredData, setFilteredData] = useState<CommissionRateQueueItem[]>([]);

  useEffect(() => {
    const filteredData = data.filter(item => item.status === activeStatusFilter);

    if (showNeedsReviewAtTop) {
      const needsReview = filteredData.filter(item => item.suggestedDisplayName.toLowerCase().includes('needs review'));
      const others = filteredData.filter(item => !item.suggestedDisplayName.toLowerCase().includes('needs review'));
      setFilteredData([...needsReview, ...others]);
    } else {
      setFilteredData(filteredData);
    }
  }, [data, activeStatusFilter, showNeedsReviewAtTop]);

  const handleToggleNeedsReview = () => {
    setShowNeedsReviewAtTop(!showNeedsReviewAtTop);
  };

  return (
    <>
      <div className="flex justify-between mb-5">
        <Dropdown
          selection
          options={statusOptions}
          value={activeStatusFilter}
          onChange={(_, data) => setActiveStatusFilter(data.value as CommissionRateQueueStatus)}
          className="rounded-lg !shadow-md"
        />
        <Checkbox
          label="Show 'Needs Review' at Top"
          checked={showNeedsReviewAtTop}
          onChange={handleToggleNeedsReview}
          className={`p-3 rounded-lg shadow-md ${showNeedsReviewAtTop ? 'bg-green-200' : 'bg-white'}`}
        />
      </div>
      <div className="bg-white mt-4 p-5 rounded-lg shadow-md overflow-x-auto font-montserrat">
        <Table celled striped aria-label="commission rate queue table" className="w-full max-w-[1350px]">
          <CommissionRateQueueTableHeader activeStatusFilter={activeStatusFilter} />
          <TableBody>
            {filteredData.map(item => (
              <CommissionRateQueueTableRow
                key={item.reviewItemId}
                network={networks.find(network => network.ID === item.meta.networkMerchant.networkId)}
                item={item}
                activeStatusFilter={activeStatusFilter}
                onApprove={onApprove}
                onRevise={onRevise}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default CommissionRateQueueTable;
