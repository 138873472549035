import React from 'react';
import { Segment, Accordion, Table } from 'semantic-ui-react';
import { toPrettyTime } from '../../helpers';
import './HistoryLog.css';

/**
 * This component is used to display a history of changes for a specific merchant. It renders a list
 * of changes (additions, updates, and deletions) in the merchant's data.
 *
 * @component HistoryLog
 *
 * @prop {Array<Object>} history - An array of history objects, each representing a single change.
 * Each object has the following keys:
 *    - `Timestamp` {string}: The time when the change occurred (ISO 8601 string).
 *    - `Action` {string}: Type of the action performed, such as "D" for delete, "U" for update, etc.
 *    - `TableName` {string}: The database table affected by the change.
 *    - `RecordName` {string}: The record affected by the change.
 *    - `Author` {string}: The identity of the user who made the change.
 *    - `Record` {Object}: The full record that was affected.
 *    - `Changes` {Object}: [Optional] The changes made in the record for an update ("U") action.
 *
 * @example
 * // Example usage
 * <HistoryLog history={someHistoryArray} />
 */
const HistoryLog = ({ history }) => {
  const renderLog = log => {
    switch (log.Action) {
      case 'U':
        return renderUpdateAccordionItem(log);
      default:
        return renderDefaultAccordionItem(log);
    }
  };

  const renderUpdateAccordionItem = log => {
    //if there is no Changes property on the log or the only field changed was modified_date, do not show the log
    if (!log.Changes || (Object.keys(log.Changes).length === 1 && log.Changes.modified_date)) return null;

    // if the only fields changed are modified_author && modified_date, do not show the log
    if (Object.keys(log.Changes).length === 2 && log.Changes.modified_date && log.Changes.modified_author) {
      return null;
    }

    return {
      key: log.Timestamp + Math.random(),
      title: `${toPrettyTime(log.Timestamp, { time: true })}: ${log.Author} made an update to ${log.RecordName}.`,
      content: {
        content: log.Changes && (
          <Table celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Field Name</Table.HeaderCell>
                <Table.HeaderCell>Previous Value</Table.HeaderCell>
                <Table.HeaderCell>Updated Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(log.Changes)
                .filter(([fieldName]) => fieldName !== 'modified_date')
                .map(([fieldName, fieldValues]) => (
                  <Table.Row key={fieldName}>
                    <Table.Cell>{fieldName}</Table.Cell>
                    <Table.Cell>{String(fieldValues.Prev)}</Table.Cell>
                    <Table.Cell>{String(fieldValues.Current)}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        ),
      },
    };
  };

  const renderDefaultAccordionItem = log => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const record = (({ modified_date, created_date, ...rest }) => rest)(log.Record); //omit date fields from displayed data
    return {
      key: log.Timestamp + Math.random(),
      title: `${toPrettyTime(log.Timestamp, { time: true })}: ${log.Author} ${
        log.Action === 'I' ? 'created' : 'deleted'
      } a "${log.TableName}" record: ${log.RecordName}.`,
      content: {
        content: record && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                {Object.keys(record).map(fieldName => (
                  <Table.HeaderCell key={fieldName + Math.random()}>{fieldName}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                {Object.values(record).map(fieldValue => (
                  <Table.Cell key={fieldValue + Math.random()}>{String(fieldValue)}</Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        ),
      },
    };
  };

  return (
    <Segment.Group className="history-log bg-white">
      <h3 className="header sticky-title" style={{ paddingLeft: '15px' }}>
        History Log
      </h3>
      {history.length === 0 && <p style={{ textAlign: 'center' }}>There are currently no history logs available.</p>}
      <Accordion
        defaultActiveIndex={[]}
        panels={history.map(record => renderLog(record))}
        exclusive={false}
        fluid
        styled
      />
    </Segment.Group>
  );
};

export default HistoryLog;
